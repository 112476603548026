<template>
  <cz-dialog
    v-on="$listeners"
    v-bind="$attrs"
    :title="$t('auth.logout')"
    :width="500"
    inset
  >
    <div>
      <div class="text-body-1">{{ $t('auth.logoutMessage') }}</div>
      <div class="d-flex mt-6" style="column-gap: 15px">
        <cz-button
          color="primary"
          large
          :title="$t('common.yes')"
          @click="$emit('logout')"
        />
        <cz-button
          color="secondary"
          text
          large
          :title="$t('common.cancel')"
          @click="$emit('close')"
        />
      </div>
    </div>
  </cz-dialog>
</template>

<script>
import { CzDialog, CzButton } from '@/components';
export default {
  name: 'LogoutDialog',
  components: {
    CzDialog,
    CzButton
  }
};
</script>

<style></style>
